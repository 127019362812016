// src/components/Login.tsx
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const BASE_URL = 'https://sigmatrader-468066970522.us-west1.run.app'
// const BASE_URL = 'http://127.0.0.1:5000'
const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const response = await fetch(BASE_URL + '/balance', {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${btoa(`${username}:${password}`)}`
                }
            });
            if (response.status === 200) {
                // Authentication is successful; save details in cookies
                Cookies.set('username', username, {});
                Cookies.set('password', password, {});
                Cookies.set('authToken', 'valid-token', {});
                // Navigate to the account page
                navigate('/account');
            } else {
                // Authentication failed
                alert('Authentication failed. Please check your username and password.');
            }
        } catch (error) {
            console.error('Error during authentication:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    return (
        <form onSubmit={handleLogin}>
            <h2>Login</h2>
            <input 
                type="text" 
                placeholder="Username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                required 
            />
            <input 
                type="password" 
                placeholder="Password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
            />
            <button type="submit">Login</button>
        </form>
    );
};

export default Login;