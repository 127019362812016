// src/components/Account.tsx
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

interface Lot {
    security: string;
    quantity: number;
    cost_basis: number;
}

interface OptionLot {
    contract_symbol: string;
    quantity: number;
    cost_basis: number;
    expiration_date: string; // ISO formatted string
}

const BASE_URL = 'https://sigmatrader-468066970522.us-west1.run.app'
// const BASE_URL = 'http://127.0.0.1:5000'

const Account: React.FC = () => {
    const navigate = useNavigate();
    const username = Cookies.get('username');
    const password = Cookies.get('password');
    const [balance, setBalance] = useState<number | null>(null);
    const [securities, setSecurities] = useState<Lot[]>([]);
    const [options, setOptions] = useState<OptionLot[]>([]);

    // Check if the user is authenticated
    useEffect(() => {
        const fetchAccountData = async () => {
            try {
                const response = await fetch(BASE_URL + '/balance', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Basic ${btoa(`${username}:${password}`)}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status === 200) {
                    const data = await response.json();
                    setBalance(data.balance);
                    const securities = data.securities.reduce((acc: Lot[], security: Lot) => {
                        const existing = acc.find(a => a.security === security.security);
                        if (existing) {
                            // If found, update the existing asset's quantity and cost_basis
                            existing.quantity += security.quantity;
                            existing.cost_basis += security.cost_basis;
                        } else {
                            // If not found, add a new entry with the same asset details
                            acc.push({ ...security });
                        }
                        return acc;
                    }, [] as Lot[]);
                    setSecurities(securities);
                    const options = data.securities.reduce((acc: OptionLot[], option: OptionLot) => {
                        const existing = acc.find(a => a.contract_symbol === option.contract_symbol);
                        if (existing) {
                            // If found, update the existing asset's quantity and cost_basis
                            existing.quantity += option.quantity;
                            existing.cost_basis += option.cost_basis;
                        } else {
                            // If not found, add a new entry with the same asset details
                            acc.push({ ...option });
                        }
                        return acc;
                    }, [] as OptionLot[]);
                    setOptions(data.options);
                } else {
                    alert('Failed to fetch account data');
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching account data:', error);
                alert('An error occurred while fetching account data');
                navigate('/');
            }
        };
        fetchAccountData();
    }, [navigate]);

    return (
        <div>
            <h2>Welcome {username}! {balance !== null && <p>Balance: ${balance.toFixed(2)}</p>}</h2>
            <h3>Securities</h3>
            <table>
                <thead>
                    <tr>
                        <th>Stock</th>
                        <th>Quantity</th>
                        <th>Cost Basis</th>
                    </tr>
                </thead>
                <tbody>
                    {securities.map((security, index) => (
                        <tr key={index}>
                            <td>{security.security}</td>
                            <td>{security.quantity}</td>
                            <td>${security.cost_basis.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h3>Options</h3>
            <table>
                <thead>
                    <tr>
                        <th>Contract Symbol</th>
                        <th>Quantity</th>
                        <th>Cost Basis</th>
                    </tr>
                </thead>
                <tbody>
                    {options.map((option, index) => (
                        <tr key={index}>
                            <td>{option.contract_symbol}</td>
                            <td>{option.quantity}</td>
                            <td>${option.cost_basis.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Account;